import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function notariesReducer(state = initialState.notaries, action) {
  switch(action.type) {
    case types.NOTARIES_FETCH_SUCCESS:
      return action.notaries.data
    default:
      return state;
  }
}
