import React from "react";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "../../pages/NotFound/NotFound"
import Login from "../../pages/Login/Login";
import Home from "../../pages/Home/Home";
import "./App.css";

function App() {
  
  return (
    <div className="bg-gray-200 h-screen w-full px-0 mx-0">
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/home" component={Home} />
        <Route exact path="/" component={Login} />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
}

export default App;
