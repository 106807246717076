import { combineReducers } from "redux";
import apiCallsInProgress from "./apiStatusReducer";
import user from "./authReducer";
import users from "./usersReducer";
import lawyers from "./lawyersReducer";
import notaries from "./notariesReducer";
import insurers from "./insurersReducer";
import workers from "./workersReducer";
import accidents from "./accidentsReducer";
import createdPartnerId from "./partnerReducer";
import createdDistrictId from "./districtReducer";
import createdWorkerId from "./workerReducer";
import createdAccidentId from "./accidentReducer";
import professions from "./professionsReducer"

const rootReducer = combineReducers({
    apiCallsInProgress,
    user,
    users,
    lawyers,
    notaries,
    insurers,
    workers,
    accidents,
    professions,
    createdPartnerId,
    createdDistrictId,
    createdWorkerId,
    createdAccidentId,
});

export default rootReducer;
