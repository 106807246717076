import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function createWorkerReducer(state = initialState.createdWorkerId, action) {
  switch(action.type) {
    case types.WORKER_CREATE_SUCCESS:
      return action.worker.data
    default:
      return state;
  }
}
