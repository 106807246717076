import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { fetchWorkerDetail } from "../../api/workersApi";
import * as api from "../../api/workerApi";
import ErrorIndicator from "../../components/ErrorIndicator/ErrorIndicator";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import WorkerDetailsCard from "../../components/WorkerDetailsCard/WorkerDetailsCard";
import WorkerEditModal from "../../components/WorkerEditModal/WorkerEditModal";
import AppModal from "../../components/AppModal/AppModal";

const WorkerDetails = () => {

  const [workerToBeEdited, setWorkerToBeEdited] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [worker, setWorker] = useState({})

  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = (worker) => {
    setWorkerToBeEdited(worker);
    setShowEditModal(true);
  }

  let { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchDetails();
  }, [id])

  const fetchDetails = () => {
    setIsLoading(true);
    setErrorOccured(false);
    fetchWorkerDetail(id).then(res => {
      setWorker(res.data);
      setIsLoading(false);
    }).catch(_ => {
      setIsLoading(false);
      setErrorOccured(true);
    })
  }

  const editWorker = (worker) => {
    api.editWorker(worker).then(res => {
      fetchDetails();
    }).catch(err => {})
  }

  const deleteWorker = () => {
    handleCloseDeleteModal();
    api.deleteWorker(id).then( _ => {
      history.goBack();
    }).catch( _ => {
      //handle error
    })
  }

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (errorOccured) {
    return <ErrorIndicator />;
  }  
  
  return (
    <>
      <WorkerDetailsCard
        worker={worker}
        handleShowEditModal={handleShowEditModal}
        handleShowDeleteModal={handleShowDeleteModal}
      />
      <WorkerEditModal show={showEditModal} onClose={handleCloseEditModal} worker={workerToBeEdited} editWorker={editWorker}/>
      <AppModal
        title="Delete Worker" 
        show={showDeleteModal}
        onClose={handleCloseDeleteModal}
        onAccept={deleteWorker}
      />
    </>
  );
};

export default WorkerDetails;
