import * as types from "./actionTypes";
import * as accidentApi from "../../api/accidentApi";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function createAccidentSuccess(accident) {
  return {type: types.ACCIDENT_CREATE_SUCCESS, accident}
}

export function createAccident(accident) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return accidentApi
      .createAccident(accident)
      .then((accident) => {
        dispatch(createAccidentSuccess(accident))
        return accident;
      })
      .catch((error) => {
        dispatch(apiCallError(error))
        throw error;
      });
  }
}
