import { useState, useEffect } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import ModalInput from "../ModalInput/ModalInput";
import { connect } from "react-redux";

const AccidentEditModal = ({
  show,
  onClose,
  accident,
  editAccident,
  professions,
  currentProffesion
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [profession, setProffesion] = useState();

  useEffect(() => {
    setName(accident?.name);
    setDescription(accident?.description);
    setPrice(accident?.price);
    setProffesion(currentProffesion?.id);
  }, [accident]);

  const onEditAccident = () => {
    const editedAccident = {
      id: accident.id,
      name: name,
      description: description,
      price: parseFloat(price),
      professionId: profession,
    };
    editAccident(editedAccident);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Partner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalInput fieldName="Name" setValue={setName} value={name} />
        <ModalInput fieldName="Description" setValue={setDescription} value={description}/>
        <ModalInput fieldName="Price" type="number" setValue={setPrice} value={price}/>
        <Dropdown className="flex items-center justify-center mb-4">
          <Dropdown.Toggle variant="secondary" className="w-full" id="dropdown-basic">
            {currentProffesion?.name}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-full text-center">
            {professions.map((prof) => (
              <Dropdown.Item onClick={() => setProffesion(prof.id)}>{prof.name}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button className="bg-blue-accent" onClick={onEditAccident}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    professions: state.professions,
  };
}

export default connect(mapStateToProps)(AccidentEditModal);
