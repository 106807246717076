import React from "react";

const WorkerDetailsCard = ({
  worker,
  handleShowEditModal,
  handleShowDeleteModal
}) => {

  return (
    <div className="p-5">
      <div className="bg-white rounded shadow-md mt-2 w-full py-2 px-3">
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Name</div>
          <div>{worker?.name}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">PLZ</div>
          <div>{worker?.plz?.join(', ')}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">City</div>
          <div>{worker?.city}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Phone</div>
          <div>{worker?.phone}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">E-mail</div>
          <div>{worker?.email}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Website</div>
          <div>{worker?.website}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Proffesions</div>
          {worker?.professions && worker?.professions.map((el) => (
            <div className="pr-2" key={el.id}>{el.name},</div>
          ))}
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex items-center">
          <div className="font-bold w-1/6">Actions</div>
          <div className="bg-green-600 px-2 py-1 rounded-md shadow-md cursor-pointer text-white flex items-center" onClick={() => handleShowEditModal(worker)}>
            Edit Worker
          </div>
          <div className="bg-red-600 px-2 py-1 rounded-md shadow-md cursor-pointer text-white flex items-center ml-3" onClick={() => handleShowDeleteModal(worker.id)}>
            Delete Worker
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkerDetailsCard;