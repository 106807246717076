import React from "react";

const AccidentDetailsCard = ({
  accident,
  handleShowEditModal,
  handleShowDeleteModal
}) => {

  return (
    <div className="p-5">
      <div className="bg-white rounded shadow-md mt-2 w-full py-2 px-3">
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Name</div>
          <div>{accident?.name}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Description</div>
          <div>{accident?.description}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Price</div>
          <div>{accident?.price}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Proffesion</div>
          <div>{accident?.profession?.name}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex items-center">
          <div className="font-bold w-1/6">Actions</div>
          <div className="bg-green-600 px-2 py-1 rounded-md shadow-md cursor-pointer text-white flex items-center" onClick={() => handleShowEditModal(accident)}>
            Edit Worker
          </div>
          <div className="bg-red-600 px-2 py-1 rounded-md shadow-md cursor-pointer text-white flex items-center ml-3" onClick={() => handleShowDeleteModal(accident.id)}>
            Delete Worker
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccidentDetailsCard;