import React from "react";
import { BsTable } from "react-icons/bs";
import "./UsersActivity.css";

const UsersActivity = ({data}) => {

  if (data == null || data.length === 0) {
    data = [{
      "partner": {
        "id": 1,
        "name": "-",
        "address": "-",
        "city": "-",
        "phone": "-",
        "email": "-",
        "website": "-",
      },
      "visits": "-"
    }]
  }

  return (
    <div className="p-5 relative">
      <div className="bg-blue-accent text-white absolute z-10 rounded py-3 px-4 top-7 text-xl ml-3 flex items-center">
        <BsTable className="text-white"/>
        <h3 className="text-white text-xl ml-3 my-0 font-normal">Users Activity</h3>
      </div>
      <div className="bg-white rounded shadow-md mt-2">
        <div className="w-full items-end flex border-b-2 border-gray-400 py-2 px-3 height-80">
          <div className="font-bold w-col-activity-name">Name</div>
          <div className="font-bold w-col-activity-address">Address</div>
          <div className="font-bold w-col-activity-city">City</div>
          <div className="font-bold w-col-activity-phone">Phone</div>
          <div className="font-bold w-col-activity-email">Email</div>
          <div className="font-bold w-col-activity-website">Website</div>
          <div className="font-bold w-col-activity-visits text-center">Visits</div>
        </div>
        {data && data?.map(el => (
          <div className="flex items-center px-3 py-2 border-b-2 border-gray-200 hover:bg-gray-200" key={el.partner.id}>
            <div className="w-col-activity-name">{el.partner.name}</div>
            <div className="w-col-activity-address">{el.partner.address}</div>
            <div className="w-col-activity-city">{el.partner.city}</div>
            <div className="w-col-activity-phone">{el.partner.phone}</div>
            <div className="w-col-activity-email">{el.partner.email}</div>
            <div className="w-col-activity-website">{el.partner.website}</div>
            <div className="w-col-activity-visits text-center">{el.visits}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UsersActivity;