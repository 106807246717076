import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import * as authActions from "../../redux/actions/authActions";
import Users from "../Users/Users";
import Workers from "../Workers/Workers";
import SideMenu from "../../components/SideMenu/SideMenu";
import UserDetails from "../UserDetails/UserDetails";
import PlaceDetails from "../PlaceDetails/PlaceDetails";
import WorkerDetails from "../WorkerDetails/WorkerDetails";
import AccidentDetails from "../AccidentDetails/AccidentDetails";
import Accidents from "../Accidents/Accidents";
import PageNotFound from "../NotFound/NotFound";
import "./Home.css"

const Home = ({user, actions}) => {
  
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }
    if (user?.username == null) {
      history.push("/login")
    }
  }, [user])

  const onSignOut = () => {
    actions.logout();
    history.push("/login");
  }

  return (
    <>
      <SideMenu username={user.username} onSignOut={onSignOut}/>
      <div className="bg-gray-200 relative margin-left">
        <Switch>
          <Route exact path="/home/users" component={Users}/>
          <Route exact path="/home/workers" component={Workers}/>
          <Route exact path="/home/accidents" component={Accidents}/>
          <Route exact path="/home/UserDetails/:id" component={UserDetails}/>
          <Route exact path="/home/PlaceDetails/:id" component={PlaceDetails}/>
          <Route exact path="/home/WorkerDetails/:id" component={WorkerDetails}/>
          <Route exact path="/home/AccidentDetails/:id" component={AccidentDetails}/>
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      logout: bindActionCreators(authActions.logout, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
