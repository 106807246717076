import * as types from "./actionTypes";
import * as partnersApi from "../../api/partnersApi";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function createPartnerSuccess(partner) {
  return {type: types.PARTNER_CREATE_SUCCESS, partner}
}

export function createDistrictSuccess(district) {
  return {type: types.DISTRICT_CREATE_SUCCESS, district}
}

export function createPartner(partner) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return partnersApi
      .createPartner(partner)
      .then((partner) => {
        dispatch(createPartnerSuccess(partner))
        return partner;
      })
      .catch((error) => {
        dispatch(apiCallError(error))
        throw error;
      });
  }
}

export function addPartner(partner) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return partnersApi
      .addPartner(partner)
      .then((partner) => {
        dispatch(createPartnerSuccess(partner))
        return partner;
      })
      .catch((error) => {
        dispatch(apiCallError(error))
        throw error;
      });
  }
}

export function createDistrict(district) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return partnersApi
      .createDistrict(district)
      .then((district) => {
        dispatch(createDistrictSuccess(district))
        return district;
      })
      .catch((error) => {
        dispatch(apiCallError(error))
        throw error;
      });
  }
}
