import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import ModalInput from "../ModalInput/ModalInput";
import { connect } from "react-redux";

const WorkerAddModal = ({
  showAddModal,
  handleCloseAddModal,
  createWorker,
  professions,
}) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState();
  const [errorText, setErrorText] = useState('');

  const selectProfession = (event) => {
    if (event.target.value == "") {
      return;
    }
    setSelectedProfession(event.target.value);
  };

  const addProfession = () => {
    if (selectedProfessions.indexOf(selectedProfession) !== -1) {
      return;
    }
    setSelectedProfessions([...selectedProfessions, selectedProfession]);
  };

  const removeProfession = (id) => {
    const filtered = selectedProfessions.filter((el) => {
      return el != id;
    });

    setSelectedProfessions(filtered);
  };

  const professionForId = (id) => {
    const p = professions.find((el) => {
      return el.id == id;
    });
    if (!p) {
      return "";
    }
    return p.name;
  };

  const onCreateWorker = async () => {
    setErrorText('');

    const ids = selectedProfessions.map((id) => parseInt(id));
    const worker = {
      name: name,
      plz: address.replaceAll(' ', '').split(','),
      city: city,
      phone: phone,
      email: email,
      website: website,
      professions: ids,
    };
    const error = await createWorker(worker);

    if (error) {
      setErrorText(error);
      return;
    }

    setSelectedProfessions([]);
    handleCloseAddModal();
  };

  return (
    <Modal show={showAddModal} onHide={handleCloseAddModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Worker</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalInput fieldName="Name" setValue={setName} />
        <ModalInput fieldName="PLZ" setValue={setAddress} />
        <ModalInput fieldName="City" setValue={setCity} />
        <ModalInput fieldName="Phone" type="number" setValue={setPhone} />
        <ModalInput fieldName="E-mail" setValue={setEmail} />
        <ModalInput fieldName="Website" setValue={setWebsite} />
        <div className="flex flex-col">
          <div className="flex justify-between mb-2">
            <select
              onChange={selectProfession}
              class="bg-gray-200 border border-gray-900 rounded outline-none py-2 px-2 flex-1 mr-3"
            >
              <option value={null}>Please choose a profession</option>
              {professions &&
                professions.map((prof, idx) => {
                  return (
                    <option key={prof.id} value={prof.id}>
                      {prof.name}
                    </option>
                  );
                })}
            </select>
            <button onClick={addProfession} className="underline">
              Add
            </button>
          </div>
          <span className="font-bold">Selected Professions:</span>
          {selectedProfessions.map((prof) => {
            return (
              <div key={prof} className="flex items-center">
                <span>{professionForId(prof)}</span>
                <button onClick={() => removeProfession(prof)}>
                  {" "}
                  <IoCloseSharp className="ml-1 text-red-500" />
                </button>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <p className="text-red-500">{errorText}</p>
        <Button variant="secondary" onClick={handleCloseAddModal}>
          Close
        </Button>
        <Button className="bg-blue-accent" onClick={onCreateWorker}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    professions: state.professions,
  };
}

export default connect(mapStateToProps)(WorkerAddModal);
