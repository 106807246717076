import React from "react";
import { Link } from "react-router-dom";
import { IoAdd, IoFilter } from "react-icons/io5";
import "./WorkersDataTable.css";

const WorkersDataTable = ({ data, handleShowAddModal, handleShowFilterModal }) => {
  
  const professionsForWorkers = (workers) => {
    let cols = [];
    for(let i = 0; i < 3; i++) {
      if (workers == null || i >= workers.length || workers[i] == null) {
        // makes uuid key for profession by Math.random
        cols.push(<div className="width-col-profession text-center" key={Math.random().toString(36).substr(2, 9)}></div>)
        continue;
      }
      cols.push(<div className="width-col-profession text-center" key={Math.random().toString(36).substr(2, 9)}>{workers[i].name}</div>)
    }
    return cols;
  }

  return (
    <div className="bg-white rounded shadow-md mt-2">
      <div className="flex justify-end pr-2 pt-2">
        <div className="flex rounded mr-2 bg-blue-accent items-center py-1 px-3 text-white cursor-pointer shadow-md" onClick={handleShowAddModal}>
          <IoAdd className="mr-2 text-white"/>
          Add New
        </div>
        <div className="flex rounded bg-blue-accent items-center py-1 px-3 text-white cursor-pointer shadow-md" onClick={handleShowFilterModal}>
          <IoFilter className="mr-2"/>
          Filter
        </div>
      </div>
      <div className="w-full items-end flex height-50 border-b-2 border-gray-400 py-2 px-3">
        <div className="font-bold width-col-name">Name</div>
        <div className="font-bold width-col-city">City</div>
        <div className="font-bold width-col-phone">Phone</div>
        <div className="font-bold width-col-profession text-center"></div>
        <div className="font-bold width-col-profession text-center">Proffesions</div>
        <div className="font-bold width-col-profession text-center"></div>
      </div>
      <div className="height-table overflow-auto w-full">
        {data &&
          data.map((el, idx) => (
            <div
              className="flex items-center px-3 py-2 border-b-2 border-gray-200 hover:bg-gray-200"
              key={idx}
            >
              <Link to={`/home/WorkerDetails/${el.id}`} className="width-col-name cursor-pointer">{el.name}</Link>
              <div className="width-col-city">{el.city}</div>
              <div className="width-col-phone">{el.phone}</div>
              {professionsForWorkers(el.professions)}
            </div>
          ))}
      </div>
    </div>
  );
};

export default WorkersDataTable;
