import * as types from "./actionTypes";
import * as workerApi from "../../api/workerApi";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function createWorkerSuccess(worker) {
  return {type: types.WORKER_CREATE_SUCCESS, worker}
}

export function createWorker(worker) {
  return function(dispatch) {
    dispatch(beginApiCall());
    return workerApi
      .createWorker(worker)
      .then((worker) => {
        dispatch(createWorkerSuccess(worker))
        return worker;
      })
      .catch((error) => {
        dispatch(apiCallError(error))
        throw error;
      });
  }
}
