import {callApiGet, callApiDelete, callApiPut} from "./apiUtils";

export function fetchAccidents(name, priceMin, priceMax, profession, page) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/accident?name=${name}&priceMin=${priceMin}&priceMax=${priceMax}&professionId=${profession}&page=${page}`
  return callApiGet(url)
}

export function fetchAccidentDetail(id) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/accident/${id}`
  return callApiGet(url)
}

export function deleteAccident(id) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/accident/${id}`
  return callApiDelete(url)
}

export function editAccident(accident) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/accident`
  return callApiPut(url, accident)
}