import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function createAccidentReducer(state = initialState.createdAccidentId, action) {
  switch(action.type) {
    case types.ACCIDENT_CREATE_SUCCESS:
      return action;
    default:
      return state;
  }
}
