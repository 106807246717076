import * as types from "./actionTypes";
import * as accidentsApi from "../../api/accidentsApi";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function accidentsFetchSuccess(accidents) {
  return {type: types.ACCIDENTS_FETCH_SUCCESS, accidents}
}

export function fetchAccidents(name, priceMin, priceMax, profession, page) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return accidentsApi
      .fetchAccidents(name, priceMin, priceMax, profession, page)
      .then((accidents) => {
        dispatch(accidentsFetchSuccess(accidents.data))
      })
      .catch((error) => {
        dispatch(apiCallError(error))
        throw error;
      });
  }
}
