import {callApiPost, callApiPut, callApiDelete} from "./apiUtils";

export function createWorker(worker) {
  const workerUrl = `${process.env.REACT_APP_BACKEND_URL}/worker`
  return callApiPost(workerUrl, worker)
}

export function editWorker(worker) {
  const workerUrl = `${process.env.REACT_APP_BACKEND_URL}/worker`
  return callApiPut(workerUrl, worker)
}

export function deleteWorker(id) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/worker/${id}`
  return callApiDelete(url)
}