export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const USERS_FETCH_SUCCESS = "USERS_FETCH_SUCCESS";
export const LAWYERS_FETCH_SUCCESS = "LAWYERS_FETCH_SUCCESS";
export const NOTARIES_FETCH_SUCCESS = "NOTARIES_FETCH_SUCCESS";
export const INSURERS_FETCH_SUCCESS = "INSURERS_FETCH_SUCCESS";
export const PARTNER_CREATE_SUCCESS = "PARTNER_CREATE_SUCCESS";
export const DISTRICT_CREATE_SUCCESS = "DISTRICT_CREATE_SUCCESS";
export const WORKERS_FETCH_SUCCESS = "WORKERS_FETCH_SUCCESS";
export const WORKER_CREATE_SUCCESS = "WORKER_CREATE_SUCCESS";
export const PROFESSIONS_FETCH_SUCCESS = "PROFESSIONS_FETCH_SUCCESS";
export const ACCIDENTS_FETCH_SUCCESS = "ACCIDENTS_FETCH_SUCCESS";
export const ACCIDENT_CREATE_SUCCESS = "ACCIDENT_CREATE_SUCCESS";
