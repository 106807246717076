import React from "react";
import { BsTable } from "react-icons/bs";
import "./UserPropertiesCard.css";

const UserProperiesCard = ({data}) => {

  const roundNumber = (number) => {
    if (typeof number !== 'number') {
      return number;
    } 
    return number.toFixed(2);
  }

  if (data == null || data.length === 0) {
    data = [{
      "id": 1,
      "title": "-",
      "city": "-",
      "postalCode": "-",
      "price": "-",
      "rentPrice": "-",
      "livingSurface": "-",
    }]
  }

  return (
    <div className="px-5 relative">
      <div className="bg-blue-accent text-white absolute z-10 rounded py-3 px-4 -top-7 text-xl ml-3 flex items-center">
        <BsTable className="text-white"/>
        <h3 className="text-white text-xl ml-3 my-0 font-normal">Properties</h3>
      </div>
      <div className="bg-white rounded shadow-md mt-2">
        <div className="w-full items-end flex height-50 border-b-2 border-gray-400 py-2 px-3 height-80">
          <div className="font-bold w-col-title">Title</div>
          <div className="font-bold w-col-city text-center">City</div>
          <div className="font-bold w-col-postal-code text-center">Postal Code</div>
          <div className="font-bold w-col-price text-center">Price</div>
          <div className="font-bold w-col-rent-price text-center">Rent Price</div>
          <div className="font-bold w-col-living-surface text-center">Living Surface</div>
        </div>
        {data && data.map(el => (
          <div className="flex items-center px-3 py-2 border-b-2 border-gray-200 hover:bg-gray-200" key={el.id}>
            <div className="w-col-title">{el.title}</div>
            <div className="w-col-city text-center">{el.city}</div>
            <div className="w-col-postal-code text-center">{el.postalCode}</div>
            <div className="w-col-price text-center">{el.price}</div>
            <div className="w-col-rent-price text-center">{roundNumber(el.rentPrice)}</div>
            <div className="w-col-living-surface text-center">{el.livingSurface}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UserProperiesCard;