import React from "react";
import { Link } from "react-router-dom";
import { IoAdd, IoFilter } from "react-icons/io5";
import "./AccidentsDataTable.css";

const AccidentsDataTable = ({ data, handleShowAddModal, handleShowFilterModal }) => {

  return (
    <div className="bg-white rounded shadow-md mt-2">
      <div className="flex justify-end pr-2 pt-2">
        <div className="flex rounded mr-2 bg-blue-accent items-center py-1 px-3 text-white cursor-pointer shadow-md" onClick={handleShowAddModal}>
          <IoAdd className="mr-2 text-white"/>
          Add New
        </div>
        <div className="flex rounded bg-blue-accent items-center py-1 px-3 text-white cursor-pointer shadow-md" onClick={handleShowFilterModal}>
          <IoFilter className="mr-2"/>
          Filter
        </div>
      </div>
      <div className="w-full items-end flex height-50 border-b-2 border-gray-400 py-2 px-3">
        <div className="font-bold width-col-name">Name</div>
        <div className="font-bold width-col-description">Description</div>
        <div className="font-bold width-col-price">Price</div>
        <div className="font-bold width-col-profession text-center">Proffesion</div>
      </div>
      <div className="height-table overflow-auto w-full">
        {data &&
          data.map((el, idx) => (
            <div
              className="flex items-center px-3 py-2 border-b-2 border-gray-200 hover:bg-gray-200"
              key={idx}
            >
              <Link to={`/home/AccidentDetails/${el.id}`} className="width-col-name cursor-pointer">{el.name}</Link>
              <div className="width-col-description">{el.description}</div>
              <div className="width-col-price">{el.price}</div>
              <div className="width-col-profession text-center">{el.profession.name}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AccidentsDataTable;
