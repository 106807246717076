import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function workersReducer(state = initialState.workers, action) {
  switch (action.type) {
    case types.WORKERS_FETCH_SUCCESS:
      return action;
    default:
      return state;
  }
}
