import React from "react";
import SideMenuAccordion from "../SideMenuAccordion/SideMenuAccordion";
import SideMenuLink from "../SideMenuLink/SideMenuLink";
import "./SideMenu.css"

const SideMenu = ({username, onSignOut}) => {

  return (
    <div className="2xl:w-1/6 md:w-1/5 bg-sidemenu bg-no-repeat text-center">
      <h1 className="m-0 py-4 text-white xl:text-4xl md:text-2xl font-bold">IMMO<br/><span className="text-blue-accent">CEPTION</span></h1>
      <ul className="list-unstyled components px-3">
        <SideMenuAccordion username={username} onSignOut={onSignOut}/>
        <SideMenuLink optionName="Users" route="/home/users"/>
        <SideMenuLink optionName="Workers" route="/home/workers"/>
        <SideMenuLink optionName="Accidents" route="/home/accidents"/>
      </ul>
    </div>
  )
}

export default SideMenu;
