import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingIndicator = () => {

  return (
    <div className="p-5">
      <div className="bg-white rounded shadow-md mt-2 w-full">
        <div className="p-9 flex flex-col items-center justify-center">
          <div className="font-bold text-lg mb-2">Loading...</div>
          <Spinner animation="border" role="status"/>
        </div>
      </div>
    </div>
  )
}

export default LoadingIndicator;
