// eslint-disable-next-line import/no-anonymous-default-export
export default {
    user: {},
    users: {},
    lawyers: {},
    notaries: {},
    insurers: {},
    workers: {},
    accidents: {},
    professions: [],
    createdPartnerId: -1,
    createdDistrictId: -1,
    createdWorkerId: -1,
    createdAccidentId: -1,
    apiCallsInProgress: 0,
};
