import * as types from "./actionTypes";
import * as authApi from "../../api/authApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loginSuccess(user) {
  return { type: types.LOGIN_SUCCESS, user };
}

export function logoutSuccess() {
  return { type: types.LOGOUT }
}

export function login(username, password) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .login(username, password)
      .then((user) => {
        dispatch(loginSuccess(user));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function logout() {
  return function (dispatch) {
    dispatch(logoutSuccess());
  }
}
