import { Modal, Button } from "react-bootstrap";

const AppModal = ({show, onClose, onAccept, title, message="Are you sure?"}) => {

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button className="bg-blue-accent" onClick={onAccept}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AppModal;
