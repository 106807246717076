import {callApiGet} from "./apiUtils";

export function fetchWorkers(name, city, plz, page) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/worker?page=${page}&name=${name}&city=${city}&plz=${plz}`
  return callApiGet(url)
}

export function fetchWorkerDetail(id) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/worker/${id}`
  return callApiGet(url)
}

export function fetchProfessions() {
  const url = `${process.env.REACT_APP_BACKEND_URL}/worker/professions`
  return callApiGet(url)
}
