import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { resetUser, resetUserPassword, deleteUser, fetchUserDetail } from "../../api/usersApi";
import ErrorIndicator from "../../components/ErrorIndicator/ErrorIndicator";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import UserDetailsCard from "../../components/UserDetailsCard/UserDetailsCard";
import UserProperiesCard from "../../components/UserPropertiesCard/UserPropertiesCard";
import AppModal from "../../components/AppModal/AppModal";
import UsersActivity from "../../components/UsersActivity/UsersActivity";

const UserDetails = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [user, setUser] = useState({})

  const handleShowResetModal = () => setShowResetModal(true);
  const handleCloseResetModal = () => setShowResetModal(false);
  const handleShowResetPasswordModal = () => setShowResetPasswordModal(true);
  const handleCloseResetPasswordModal = () => setShowResetPasswordModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  let { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchDetails();
  }, [id])

  const fetchDetails = () => {
    setIsLoading(true);
    setErrorOccured(false);
    fetchUserDetail(id).then(res => {
      setUser(res.data);
      setIsLoading(false);
    }).catch(_ => {
      setIsLoading(false);
      setErrorOccured(true);
    })
  }

  const onResetUser = () => {
    handleCloseResetModal();
    resetUser(id).then( _ => {
      fetchDetails();
    }).catch( _ => {
      // handle error
    })
  }  

  const onResetUserPassword = () => {
    handleCloseResetPasswordModal();
    resetUserPassword(id).then( _ => {
      
    }).catch( _ => {
      // handle error
    })
  }

  const onDeleteUser = () => {
    handleCloseDeleteModal();
    deleteUser(id).then( _ => {
      history.goBack();
    }).catch( _ => {
      //handle error
    })
  }

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (errorOccured) {
    return <ErrorIndicator />;
  }
  
  return (
    <>
      <UserDetailsCard
        username={(user?.userInfo?.username)}
        firstName={(user?.userInfo?.firstName)}
        lastName={(user?.userInfo?.lastName)}
        emailAddress={(user?.userInfo?.emailAddress)}
        createdAt={(user?.userInfo?.createdAt)}
        lastSeen={(user?.userInfo?.lastSeen)}
        isActive={(user?.userInfo?.isActive)}
        isAdmin={(user?.userInfo?.isAdmin)}
        logingCount={(user?.userInfo?.logingCount)}
        onShowResetModal={handleShowResetModal}
        onShowResetPasswordModal={handleShowResetPasswordModal}
        onShowDeleteModal={handleShowDeleteModal}
      />
      <UserProperiesCard data={user?.properties} />
      <UsersActivity data={user?.partnersVisits}/>
      <AppModal
        title="Reset User" 
        show={showResetModal}
        onClose={handleCloseResetModal}
        onAccept={onResetUser}
      />
      <AppModal
        title="Reset User's password" 
        show={showResetPasswordModal}
        onClose={handleCloseResetPasswordModal}
        onAccept={onResetUserPassword}
      />
      <AppModal
        title="Delete User" 
        show={showDeleteModal}
        onClose={handleCloseDeleteModal}
        onAccept={onDeleteUser}
      />
    </>
  );
};

export default UserDetails;
