import { InputGroup, FormControl } from "react-bootstrap";

const ModalInput = ({fieldName, setValue, value, type = "text"}) => {

  return (
    <InputGroup className="mb-3">
      <InputGroup.Prepend>
        <InputGroup.Text>
          {fieldName}
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl type={type} onChange={(e) => setValue(e.target.value)} value={value}/>
    </InputGroup>
  )
}

export default ModalInput;
