import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function insurersReducer(state = initialState.insurers, action) {
  switch(action.type) {
    case types.INSURERS_FETCH_SUCCESS:
      return action.insurers.data
    default:
      return state;
  }
}
