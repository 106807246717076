import { React, useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import ModalInput from "../ModalInput/ModalInput";
import { connect } from "react-redux";

const AccidentsFilterModal = ({showFilterModal, handleCloseFilterModal, setFilters, professions}) => {

  const [name, setName] = useState("");
  const [priceMin, setPriceMin] = useState("");
  const [priceMax, setPriceMax] = useState("");
  const [profession, setProfession] = useState({ name: "Profession"});

  const setFiltersAndClose = () => {
    setFilters(name, priceMin, priceMax, profession.id);
    handleCloseFilterModal();
  }

  return (
    <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
      <Modal.Header closeButton>
        <Modal.Title>Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalInput fieldName="Name" setValue={setName}/>
        <ModalInput fieldName="Min price" type="number" setValue={setPriceMin}/>
        <ModalInput fieldName="Max price" type="number" setValue={setPriceMax}/>
        <Dropdown className="flex items-center justify-center mb-4">
          <Dropdown.Toggle variant="secondary" className="w-full" id="dropdown-basic">
            {profession?.name}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-full text-center" defaultValue="Profession">
            {professions.map((prof) => (
              <Dropdown.Item onClick={() => setProfession(prof)}>{prof.name}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseFilterModal}>
          Close
        </Button>
        <Button className="bg-blue-accent" onClick={setFiltersAndClose}>
          Filter
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function mapStateToProps(state) {
    return {
      professions: state.professions,
    };
  }
  
export default connect(mapStateToProps)(AccidentsFilterModal);
  