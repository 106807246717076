import * as types from "./actionTypes";
import * as usersApi from "../../api/usersApi";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function usersFetchSuccess(users) {
  return {type: types.USERS_FETCH_SUCCESS, users}
}

export function fetchUsers(page) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return usersApi
      .fetchUsers(page)
      .then((users) => {
        dispatch(usersFetchSuccess(users))
      })
      .catch((error) => {
        dispatch(apiCallError(error))
        throw error;
      });
  }
}
