import React from "react";
import { MdFastRewind, MdFastForward } from "react-icons/md";

const TablePaginator = ({prevPage, nextPage, page, maxPage}) => {

  return (
    <>
      <button onClick={prevPage} className="fixed bottom-4 left-1/6 bg-blue-accent text-white rounded py-2 px-4 flex items-center">
        <MdFastRewind className="text-white mr-2"/>
        Previous
      </button>
      <div className="fixed right-1/4 bottom-6">Seite {page}/{maxPage}</div>
      <button onClick={nextPage} className="fixed bottom-4 right-12 bg-blue-accent text-white rounded py-2 px-4 flex items-center">
        Next
        <MdFastForward className="text-white ml-2"/>
      </button>
    </>
  )
}

export default TablePaginator;