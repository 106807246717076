import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import * as authActions from '../../redux/actions/authActions';
import "./Login.css"

const Login = ({actions}) => {
  
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setErrors(false);
    setLoading(true);
    actions.login(username, password).then(_ => {
      setLoading(false);
      history.push("/home/users")
    }).catch(error => {
      setLoading(false);
      setErrors(true);
    });
  }

  return (
    <div className="vh-100 bg-gray-200 m-0 w-screen">
      <div className="flex w-100 h-100 justify-content-center align-items-center">
        <form className="relative text-center bg-white rounded shadow-md flex flex-column justify-around content-center py-2 login-form" onSubmit={onSubmit}>
          <h1 className="h3 font-weight-normal mb-0">Please sign in</h1>
          <div className="form-group w-3/4 mx-auto my-0">
            <label htmlFor="inputUsername" className="sr-only">Email address</label>
            <input type="text" id="inputUsername" className="form-control mb-2" placeholder="Username" required={true}
                   autoFocus="" onChange={(e) => setUsername(e.target.value)}/>
            <label htmlFor="inputPassword" className="sr-only">Password</label>
            <input type="password" id="inputPassword" className="form-control" placeholder="Password" required={true}
                   onChange={(e) => setPassword(e.target.value)}/>
            {errors && <span className="text-danger mt-2 absolute w-full left-0">Wrong email or password</span>}
            {loading && <div className="mt-2 absolute w-full left-0">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>}
          </div>
          <button className="btn-lg bg-blue-accent text-white w-3/4 mx-auto">Sign in</button>
        </form>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(authActions.login, dispatch),
    },
  };
}

export default connect(null , mapDispatchToProps)(Login);
