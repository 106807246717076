import React from "react";
import { BsTable } from "react-icons/bs";
import { IoAddSharp, IoCloseSharp } from "react-icons/io5";
import { BsPencil } from "react-icons/bs";
import "./PlacePartnersCard.css";

const PlacePartnersCard = ({place, handleShowAddModal, handleShowEditModal, handleShowDeleteModal}) => {

  return (
    <div className="px-5 relative">
      <div className="bg-blue-accent text-white absolute z-10 rounded py-3 px-4 -top-7 text-xl ml-3 flex items-center">
        <BsTable className="text-white"/>
        <h3 className="text-white text-xl ml-3 my-0 font-normal">Partners</h3>
      </div>
      <div className="bg-white rounded shadow-md mt-2">
        <div className="h-8"/>
        <div className="w-full items-end flex height-50 border-b-2 border-gray-400 py-2 px-3">
          <div className="font-bold w-col-name">Name</div>
          <div className="font-bold w-col-address">Address</div>
          <div className="font-bold w-col-city">City</div>
          <div className="font-bold w-col-phone">Phone</div>
          <div className="font-bold w-col-type">Type</div>
          <div className="font-bold w-col-website">Website</div>
          <div className="font-bold w-col-actions text-center">Actions</div>
        </div>
        {place?.partners?.map(p => {
          return (
            <div className="flex items-center px-3 py-2 border-b-2 border-gray-200 hover:bg-gray-200" key={p.id}>
              <div className="w-col-name">{p.name}</div>
              <div className="w-col-address">{p.address}</div>
              <div className="w-col-city">{p.city}</div>
              <div className="w-col-phone">{p.phone}</div>
              <div className="w-col-type">{p.type}</div>
              <div className="w-col-website">{p.website}</div>
              <div className="w-col-actions flex items-center content-center justify-center">
                <div className="bg-red-600 mr-2 p-1 rounded-md shadow-md cursor-pointer" onClick={() => handleShowDeleteModal(p)}>
                  <IoCloseSharp className="text-white text-md"/>
                </div>
                <div className="bg-green-600 p-1 rounded-md shadow-md cursor-pointer" onClick={() => handleShowEditModal(p)}>
                  <BsPencil className="text-white text-md"/>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PlacePartnersCard;
