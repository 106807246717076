import axios from "axios";

export async function handleResponse(response) {
  if (response.status === 200) return response.data;
  if (response.status === 400) {
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

export function handleError(error) {
  throw error;
}

export function callApiPost(url, data) {
  const token = localStorage.getItem("token")
  return axios.post(url, data, {headers: {"Authorization": `Bearer ${token}`}})
}

export function callApiPut(url, data) {
  const token = localStorage.getItem("token")
  return axios.put(url, data, {headers: {"Authorization": `Bearer ${token}`}})
}

export function callApiDelete(url, data) {
  const token = localStorage.getItem("token")
  return axios.delete(url, {headers: {"Authorization": `Bearer ${token}`}})
}

export function callApiGet(url) {
  const token = localStorage.getItem("token")
  return axios.get(url, {headers: {"Authorization": `Bearer ${token}`}})
}
