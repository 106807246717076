import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useParams} from 'react-router';
import {fetchPlaceDetails} from '../../api/placesApi';
import * as api from '../../api/partnersApi';
import * as partnersActions from '../../redux/actions/partnersActions';
import PartnerAddModal from '../../components/PartnerAddModal/PartnerAddModal';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import PlaceDetailsCard from '../../components/PlaceDetailsCard/PlaceDetailsCard';
import PlacePartnersCard from '../../components/PlacePartnersCard/PlacePartnersCard';
import PartnerEditModal from '../../components/PartnerEditModal/PartnerEditModal';
import PartnerDeleteModal from '../../components/PartnerDeleteModal/PartnerDeleteModal';
import ErrorIndicator from '../../components/ErrorIndicator/ErrorIndicator';

//createdPartnerId is here in case of future error handling implementation
const PlaceDetails = ({actions, createdPartnerId}) => {
  const [partnerToBeDeleted, setPartnerToBeDeleted] = useState({});
  const [partnerToBeEdited, setPartnerToBeEdited] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [place, setPlace] = useState({});

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowEditModal = (partner) => {
    setPartnerToBeEdited(partner);
    setShowEditModal(true);
  };
  const handleShowDeleteModal = (partner) => {
    setPartnerToBeDeleted(partner);
    setShowDeleteModal(true);
  };

  let {id} = useParams();

  useEffect(() => {
    fetchDetails();
  }, [id]);

  const fetchDetails = () => {
    setIsLoading(true);
    setErrorOccured(false);
    fetchPlaceDetails(id)
      .then((res) => {
        setPlace(res.data);
        setIsLoading(false);
      })
      .catch((_) => {
        setIsLoading(false);
        setErrorOccured(true);
      });
  };

  const editPartner = async (partner) => {
    try {
      await api.editPartner(partner);
      return '';
    } catch (err) {
      return err.response.data;
    }
  };

  const removePartner = (partner) => {
    api.removePartnerFromPlace(partner.id).then((_) => {
      fetchDetails();
      handleCloseDeleteModal();
    });
  };

  // Render corresponding component based on fetching data state
  if (isLoading) {
    return <LoadingIndicator />;
  } else if (errorOccured) {
    return <ErrorIndicator />;
  }

  return (
    <>
      <PlaceDetailsCard place={place} />
      <PlacePartnersCard
        place={place}
        handleShowDeleteModal={handleShowDeleteModal}
        handleShowEditModal={handleShowEditModal}
      />
      <PartnerDeleteModal
        show={showDeleteModal}
        onClose={handleCloseDeleteModal}
        onDelete={removePartner}
        partner={partnerToBeDeleted}
      />
      <PartnerEditModal
        show={showEditModal}
        onClose={handleCloseEditModal}
        partner={partnerToBeEdited}
        editPartner={editPartner}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    createdPartnerId: state.createdPartnerId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createPartner: bindActionCreators(
        partnersActions.createPartner,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceDetails);
