import {callApiDelete, callApiPost, callApiPut} from './apiUtils';
import {INSURER, NOTARY, LAWYER} from '../constants';

const partnersUrl = process.env.REACT_APP_BACKEND_URL + '/partner';
const districtsUrl = partnersUrl + '/district';

export function createPartner(partner) {
  return callApiPost(partnersUrl, partner);
}

export function createDistrict(district) {
  return callApiPost(districtsUrl, district);
}

export function addPartnerToPlace(partnerId, placeId, partnerType) {
  let placeUrl = `${process.env.REACT_APP_BACKEND_URL}/partner/lawyers/${placeId}`;
  if (partnerType === INSURER) {
    placeUrl = `${process.env.REACT_APP_BACKEND_URL}/partner/insurers/${placeId}`;
  } else if (partnerType === NOTARY) {
    placeUrl = `${process.env.REACT_APP_BACKEND_URL}/partner/notaries/${placeId}`;
  }
  const body = {partnerId: partnerId};
  return callApiPost(placeUrl, body);
}

export function addPartner(partner, partnerType) {
  let endpoint;
  if (partnerType === LAWYER) {
    endpoint = '/Partner/Lawyer';
  } if (partnerType === INSURER) {
    endpoint = '/Partner/Insurer';
  } if (partnerType === NOTARY) {
    endpoint = '/Partner/Notary';
  }
  return callApiPost(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, partner);
}

export async function editPartner(partner) {
  const res = await callApiPut(partnersUrl, partner);
  console.log(res);
  return res;
}

export function removePartnerFromPlace(partnerId) {
  let placeUrl = `${process.env.REACT_APP_BACKEND_URL}/partner/${partnerId}`;
  return callApiDelete(placeUrl);
}
