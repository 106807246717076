import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function professionsReducer(state = initialState.professions, action) {
  switch (action.type) {
    case types.PROFESSIONS_FETCH_SUCCESS:
      return action.professions.data.professions
    default:
      return state
  }
}
