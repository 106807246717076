import React from "react";

const ErrorIndicator = () => {

  return (
    <div className="p-5">
      <div className="bg-white rounded shadow-md mt-2 w-full">
        <div className="p-10 font-bold text-lg text-center">Error Occurred</div>
      </div>
    </div>
  )
}

export default ErrorIndicator;
