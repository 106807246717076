import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import ModalInput from "../ModalInput/ModalInput";
import { connect } from "react-redux";

const WorkerEditModal = ({
  show,
  onClose,
  worker,
  editWorker,
  professions,
}) => {
  const [name, setName] = useState("");
  const [plz, setPlz] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState();

  useEffect(() => {
    if (Object.keys(worker).length === 0) {
      return;
    }
    setName(worker?.name);
    setPlz(worker?.plz.join(', '));
    setCity(worker?.city);
    setPhone(worker?.phone);
    setEmail(worker?.email);
    setWebsite(worker?.website);
    const ids = worker?.professions.map((el) => el.id);
    setSelectedProfessions(ids);
  }, [worker]);

  const selectProfession = (event) => {
    if (event.target.value == "") {
      return;
    }
    setSelectedProfession(event.target.value);
  };

  const addProfession = () => {
    if (selectedProfessions.indexOf(selectedProfession) !== -1) {
      return;
    }
    setSelectedProfessions([...selectedProfessions, selectedProfession]);
  };

  const removeProfession = (id) => {
    const filtered = selectedProfessions.filter((el) => {
      return el != id;
    });

    setSelectedProfessions(filtered);
  };

  const professionForId = (id) => {
    const p = professions.find((el) => {
      return el.id == id;
    });
    if (!p) {
      return "";
    }
    return p.name;
  };

  const onEditWorker = () => {
    const ids = selectedProfessions.map((id) => parseInt(id));
    const editedWorker = {
      id: worker.id,
      name: name,
      plz: plz.replaceAll(' ', '').split(','),
      city: city,
      phone: phone,
      email: email,
      website: website,
      professions: ids,
    };
    editWorker(editedWorker);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Partner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalInput fieldName="Name" setValue={setName} value={name} />
        <ModalInput fieldName="PLZ" setValue={setPlz} value={plz} />
        <ModalInput fieldName="City" setValue={setCity} value={city} />
        <ModalInput
          fieldName="Phone"
          type="number"
          setValue={setPhone}
          value={phone}
        />
        <ModalInput fieldName="E-mail" setValue={setEmail} value={email} />
        <ModalInput fieldName="Website" setValue={setWebsite} value={website} />
        <div className="flex flex-col">
          <div className="flex justify-between mb-2">
            <select
              onChange={selectProfession}
              class="bg-gray-200 border border-gray-900 rounded outline-none py-2 px-2 flex-1 mr-3"
            >
              <option value={null}>Please choose a profession</option>
              {professions &&
                professions.map((prof, idx) => {
                  return (
                    <option key={prof.id} value={prof.id}>
                      {prof.name}
                    </option>
                  );
                })}
            </select>
            <button onClick={addProfession} className="underline">
              Add
            </button>
          </div>
          <span className="font-bold">Selected Professions:</span>
          {selectedProfessions.map((prof) => {
            return (
              <div key={prof} className="flex items-center">
                <span>{professionForId(prof)}</span>
                <button onClick={() => removeProfession(prof)}>
                  <IoCloseSharp className="ml-1 text-red-500" />
                </button>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button className="bg-blue-accent" onClick={onEditWorker}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    professions: state.professions,
  };
}

export default connect(mapStateToProps)(WorkerEditModal);
