import React from "react";
import { IoCloseSharp, IoCheckmarkSharp } from "react-icons/io5";

const UserDetailsCard = ({
  username,
  firstName,
  lastName,
  emailAddress,
  isActive,
  isAdmin,
  logingCount,
  createdAt,
  lastSeen,
  onShowResetModal,
  onShowResetPasswordModal,
  onShowDeleteModal,
}) => {

  const dateToString = (date) => {
    if (date === "0001-01-01T00:00:00") {
      return "-"
    }
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }
    return new Date(date).toLocaleDateString(undefined, options)
  }

  return (
    <div className="p-5">
      <div className="bg-white rounded shadow-md mt-2 w-full py-2 px-3">
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Username</div>
          <div>{username}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">First Name</div>
          <div>{firstName}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Last Name</div>
          <div>{lastName}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">E-mail</div>
          <div>{emailAddress}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Created At</div>
          <div>{dateToString(createdAt)}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Last Seen</div>
          <div>{dateToString(lastSeen)}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Loging Cout</div>
          <div>{logingCount}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Active</div>
          <div>                
            {isActive ? (
              <IoCheckmarkSharp className="text-green-600" />
            ) : (
              <IoCloseSharp className="text-red-600" />
            )}
          </div>
        </div>
        <div className="px-2 py-2 flex border-b-2 border-gray-200">
          <div className="font-bold w-1/6">Superuser</div>
          <div>                
            {isAdmin ? (
              <IoCheckmarkSharp className="text-green-600" />
            ) : (
              <IoCloseSharp className="text-red-600" />
            )}
          </div>
        </div>
        <div className="px-2 py-2 flex">
          <div className="font-bold w-1/6">Actions</div>
          <div className="flex items-center content-center justify-center">
            <div className="bg-yellow-500 mr-2 p-1 rounded-md shadow-md cursor-pointer" onClick={() => onShowResetModal()}>
              <div className="text-white px-2">Reset User</div>
            </div>
            <div className="bg-green-500 mr-2 p-1 rounded-md shadow-md cursor-pointer" onClick={() => onShowResetPasswordModal()}>
              <div className="text-white px-2">Reset Password</div>
            </div>
            <div className="bg-red-500 mr-2 p-1 rounded-md shadow-md cursor-pointer" onClick={() => onShowDeleteModal()}>
              <div className="text-white px-2">Delete User</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsCard;