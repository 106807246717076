import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function createDistrictReducer(state = initialState.createdDistrictId, action) {
  switch(action.type) {
    case types.DISTRICT_CREATE_SUCCESS:
      return action.district.data
    default:
      return state;
  }
}
