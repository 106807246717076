import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { fetchAccidentDetail } from "../../api/accidentsApi";
import * as api from "../../api/accidentsApi";
import ErrorIndicator from "../../components/ErrorIndicator/ErrorIndicator";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import AccidentDetailsCard from "../../components/AccidentDetailsCard/AccidentDetailsCard";
import AccidentEditModal from "../../components/AccidentEditModal/AccidentEditModal";
import AppModal from "../../components/AppModal/AppModal";

const AccidentDetails = () => {

  const [accidentToBeEdited, setAccidentToBeEdited] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [accident, setAccident] = useState({})
  const currentProffesion = accident.profession;

  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = (accident) => {
    setAccidentToBeEdited(accident);
    setShowEditModal(true);
  }

  let { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchDetails();
  }, [id])

  const fetchDetails = () => {
    setIsLoading(true);
    setErrorOccured(false);
    fetchAccidentDetail(id).then(res => {
      setAccident(res.data);
      setIsLoading(false);
    }).catch(_ => {
      setIsLoading(false);
      setErrorOccured(true);
    })
  }

  const editAccident = (accident) => {
    api.editAccident(accident).then(res => {
      fetchDetails();
    }).catch(err => {})
  }

  const deleteAccident = () => {
    handleCloseDeleteModal();
    api.deleteAccident(id).then( _ => {
      history.goBack();
    }).catch( _ => {
      //handle error
    })
  }

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (errorOccured) {
    return <ErrorIndicator />;
  }  
  
  return (
    <>
      <AccidentDetailsCard
        accident={accident}
        handleShowEditModal={handleShowEditModal}
        handleShowDeleteModal={handleShowDeleteModal}
      />
      <AccidentEditModal show={showEditModal} onClose={handleCloseEditModal} accident={accidentToBeEdited} editAccident={editAccident} currentProffesion={currentProffesion}/>
      <AppModal
        title="Delete Worker" 
        show={showDeleteModal}
        onClose={handleCloseDeleteModal}
        onAccept={deleteAccident}
      />
    </>
  );
};

export default AccidentDetails;
