import React from "react";
import { Link } from "react-router-dom";
import { IoCloseSharp, IoCheckmarkSharp } from "react-icons/io5";
import "./UsersDataTable.css";

const UsersDataTable = ({ data }) => {
  return (
    <div className="bg-white rounded shadow-md mt-2">
      <div className="w-full items-end flex height-80 border-b-2 border-gray-400 py-2 px-3">
        <div className="font-bold width-col-username">Username</div>
        <div className="font-bold width-col-name">First Name</div>
        <div className="font-bold width-col-surname">Last Name</div>
        <div className="font-bold width-col-mail">E-mail</div>
        <div className="font-bold width-col-active text-center">Active</div>
        <div className="font-bold width-col-superuser text-center">
          Superuser
        </div>
      </div>
      <div className="height-table overflow-auto w-full">
        {data &&
          data.map((el) => (
            <div
              className="flex items-center px-3 py-2 border-b-2 border-gray-200 hover:bg-gray-200"
              key={el.id}
            >
              <Link to={`/home/UserDetails/${el.id}`} className="width-col-username cursor-pointer">{el.username}</Link>
              <div className="width-col-name">{el.firstName}</div>
              <div className="width-col-surname">{el.lastName}</div>
              <div className="width-col-mail">{el.emailAddress}</div>
              <div className="width-col-active flex justify-center">
                {el.isActive ? (
                  <IoCheckmarkSharp className="text-green-600" />
                ) : (
                  <IoCloseSharp className="text-red-600" />
                )}
              </div>
              <div className="width-col-superuser flex justify-center">
                {el.isAdmin ? (
                  <IoCheckmarkSharp className="text-green-600" />
                ) : (
                  <IoCloseSharp className="text-red-600" />
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UsersDataTable;
