import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function authReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      localStorage.setItem("token", action.user.token)
      return action.user;
    case types.LOGOUT:
      return {};
    default:
      return state;
  }
}
