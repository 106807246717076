import * as types from "./actionTypes";
import * as workersApi from "../../api/workersApi";
import {apiCallError, beginApiCall} from "./apiStatusActions";

export function workersFetchSuccess(workers) {
  return {type: types.WORKERS_FETCH_SUCCESS, workers}
}

export function professionsFetchSuccess(professions) {
  return {type: types.PROFESSIONS_FETCH_SUCCESS, professions}
}

export function fetchWorkers(name, city, plz, page) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return workersApi
      .fetchWorkers(name, city, plz, page)
      .then((workers) => {
        dispatch(workersFetchSuccess(workers.data))
      })
      .catch((error) => {
        dispatch(apiCallError(error))
        throw error;
      });
  }
}

export function fetchProfessions() {
  return function (dispatch) {
    dispatch(beginApiCall())
    return workersApi
      .fetchProfessions()
      .then((professions) => {
        dispatch(professionsFetchSuccess(professions))
      })
      .catch((error) => {
        dispatch(apiCallError(error))
        throw error;
      });
  }
}
