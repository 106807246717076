import { useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import ModalInput from "../ModalInput/ModalInput";
import { connect } from "react-redux";

const AccidentAddModal = ({
  showAddModal,
  handleCloseAddModal,
  createAccident,
  professions,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [profession, setProffesion] = useState();

  const onCreateAccident = () => {
    const accident = {
      name: name,
      description: description,
      price: parseFloat(price),
      professionId: profession.id,
    };
    createAccident(accident);
    handleCloseAddModal();
  };

  return (
    <Modal show={showAddModal} onHide={handleCloseAddModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Accident</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalInput fieldName="Name" setValue={setName} />
        <ModalInput fieldName="Description" setValue={setDescription} />
        <ModalInput fieldName="Price" type="number" setValue={setPrice} />
        <Dropdown className="flex items-center justify-center mb-4">
          <Dropdown.Toggle variant="secondary" className="w-full" id="dropdown-basic">
            {profession?.name}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-full text-center">
            {professions.map((prof) => (
              <Dropdown.Item onClick={() => setProffesion(prof)}>{prof.name}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseAddModal}>
          Close
        </Button>
        <Button className="bg-blue-accent" onClick={onCreateAccident}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    professions: state.professions,
  };
}

export default connect(mapStateToProps)(AccidentAddModal);
