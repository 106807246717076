import React from "react";
import { Link } from "react-router-dom";

const SideMenuLink = ({optionName, route}) => {
  
  return (
    <li className="active py-2">
      <Link 
        className="nav-link text-white font-light hover:bg-blue-accent transition duration-200 ease-in-out rounded-md" 
        to={route} 
        data-toggle="collapse" 
        aria-expanded="false"
      >
        {optionName}
      </Link>
    </li>
  )
}

export default SideMenuLink;