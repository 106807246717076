import {callApiGet, callApiPost, callApiDelete} from "./apiUtils";

export function fetchUsers(page) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/Admin/users?page=${page}`
  return callApiGet(url)
}
 
export function fetchUserDetail(id) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/Admin/${id}`
  return callApiGet(url)
}

export function resetUser(id) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/Admin/reset/${id}`
  return callApiPost(url)
}

export function resetUserPassword(id) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/Admin/password/${id}`
  return callApiPost(url)
}

export function deleteUser(id) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/Admin/${id}`
  return callApiDelete(url)
}