import { Modal, Button } from "react-bootstrap";

const PartnerDeleteModal = ({show, onClose, onDelete, partner}) => {

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Caution</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete {partner.name}?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="danger" onClick={() => onDelete(partner)}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PartnerDeleteModal;
