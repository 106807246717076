import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as workersActions from "../../redux/actions/workersActions";
import * as accidentsActions from "../../redux/actions/accidentsActions";
import * as accidentActions from "../../redux/actions/accidentActions";
import { BsTable } from "react-icons/bs";
import TablePaginator from "../../components/TablePaginator/TablePaginator";
import AccidentsDataTable from "../../components/AccidentsDataTable/AccidentsDataTable";
import AccidentAddModal from "../../components/AccidentAddModal/AccidentAddModal";
import AccidentsFilterModal from "../../components/AccidentsFilterModal/AccidentsFilterModal";

const Accidents = ({actions, accidents}) => {

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [errorOccured, setErrorOccured] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [priceMinFilter, setPriceMinFilter] = useState("");
  const [priceMaxFilter, setPriceMaxFilter] = useState("");
  const [professionFilter, setProfessionFilter] = useState("");
  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseFilterModal = () => setShowFilterModal(false);
  const handleShowFilterModal = () => setShowFilterModal(true);

  useEffect(() => {
    if (showAddModal) return;
    
    actions.fetchAccidents(nameFilter, priceMinFilter, priceMaxFilter, professionFilter, page)
    .then(() => {
      if (accidents !== undefined) {
        setLoading(false);
      }
    })
    .catch(error => {
      setErrorOccured(true);
    })
    .finally(() => {
      if (!errorOccured) {
        setLoading(false);
      }
    });
  },[nameFilter, priceMinFilter, priceMaxFilter, professionFilter, page, showAddModal, actions])

  const setFilters = (nameFilter, priceMinFilter, priceMaxFilter, professionFilter) => {
    setNameFilter(nameFilter);
    setPriceMinFilter(priceMinFilter);
    setPriceMaxFilter(priceMaxFilter);
    setProfessionFilter(professionFilter);
    setPage(1);
  }

  useEffect(() => {
    actions.fetchProfessions()
  }, [actions.fetchProfessions])

  const nextPage = () => {
    if (page != accidents.pages) {
      setPage(page + 1)
    }
  }

  const prevPage = () => {
    setPage(Math.max(1, page - 1))
  }

  const createAccident = (accident) => {
    actions.createAccident(accident).then(res => {
      actions.fetchAccidents(page);
    }).catch(err => {})
  }

  return (
    <div className="px-5 py-5">
    <div className="bg-blue-accent text-white absolute z-10 rounded py-3 px-4 top-6 text-xl ml-3 flex items-center">
      <BsTable className="text-white"/>
      <h3 className="text-white text-xl ml-3 my-0 font-normal">Accidents</h3>
    </div>
    <AccidentAddModal showAddModal={showAddModal} handleCloseAddModal={handleCloseAddModal} createAccident={createAccident}/>
    <AccidentsFilterModal showFilterModal={showFilterModal} handleCloseFilterModal={handleCloseFilterModal} setFilters={setFilters}/>
    {errorOccured && <h1 className="px-3 py-5">Error occured</h1>}
    {loading && <h1 className="px-3 py-5">Loading...</h1>}
    {!loading && 
    <div>
      <AccidentsDataTable data={accidents.accidents} handleShowAddModal={handleShowAddModal} handleShowFilterModal={handleShowFilterModal}/>
      <TablePaginator prevPage={prevPage} page={page} maxPage={accidents.pages} nextPage={nextPage} />
    </div>}
  </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchAccidents: bindActionCreators(accidentsActions.fetchAccidents , dispatch),
      createAccident: bindActionCreators(accidentActions.createAccident, dispatch),
      fetchProfessions: bindActionCreators(workersActions.fetchProfessions, dispatch)
    }
  }
}

function mapStateToProps(state) {
  return {
    accidents: state.accidents.accidents,
    createdAccidentId: state.createdAccidentId,
    apiCallsInProgress: state.apiCallsInProgress,
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Accidents);
