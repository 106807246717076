import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as usersActions from "../../redux/actions/usersActions";
import { BsTable } from "react-icons/bs";
import UsersDataTable from "../../components/UsersDataTable/UsersDataTable";
import TablePaginator from "../../components/TablePaginator/TablePaginator";
  
const Users = ({actions, users}) => {

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [errorOccured, setErrorOccured] = useState(false);

  useEffect(() => {
    setErrorOccured(false)
    setLoading(true);
    actions.fetchUsers(page).catch(error => {
      setErrorOccured(true);
    })
    .finally(() => {
      setLoading(false);
    })
  },[page, actions])

  const nextPage = () => {
    if (page != users.pages) {
      setPage(page + 1)
    }
  }

  const prevPage = () => {
    setPage(Math.max(1, page - 1))
  }

  return (
    <div className="px-5 py-5">
    <div className="bg-blue-accent text-white absolute z-10 rounded py-3 px-4 top-6 text-xl ml-3 flex items-center">
      <BsTable className="text-white"/>
      <h3 className="text-white text-xl ml-3 my-0 font-normal">Users</h3>
    </div>
    {loading && <h1 className="px-3 py-5">Loading...</h1>}
    {errorOccured && <h1 className="px-3 py-5">Error occured</h1>}
    {!loading && !errorOccured && <UsersDataTable data={users.usersInfo}/>}
    <TablePaginator prevPage={prevPage} page={page} maxPage={users.pages} nextPage={nextPage} />
  </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchUsers: bindActionCreators(usersActions.fetchUsers, dispatch),
    }
  }
}

function mapStateToProps(state) {
  return {
    users: state.users,
    apiCallsInProgress: state.apiCallsInProgress,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);