import { React, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ModalInput from "../ModalInput/ModalInput";

const WorkersFilterModal = ({showFilterModal, handleCloseFilterModal, setFilters}) => {

  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [plz, setPlz] = useState("");

  const setFiltersAndClose = () => {
    setFilters(name, city, plz);
    handleCloseFilterModal();
  }

  return (
    <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
      <Modal.Header closeButton>
        <Modal.Title>Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalInput fieldName="Name" setValue={setName}/>
        <ModalInput fieldName="City" setValue={setCity}/>
        <ModalInput fieldName="PLZ" type="number" setValue={setPlz}/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseFilterModal}>
          Close
        </Button>
        <Button className="bg-blue-accent" onClick={setFiltersAndClose}>
          Filter
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default WorkersFilterModal;