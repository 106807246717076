import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import { AiFillCaretDown } from "react-icons/ai";

const SideMenuAccordion = ({username, onSignOut}) => {

  return (
    <li className="border-t border-b border-gray-600 py-2 px-1">
      <Accordion defaultActiveKey="1" className="text-white bg-transparent border-0">
        <Card className="text-white bg-transparent border-0 cursor-pointer">
          <Accordion.Toggle as={Card.Header} eventKey="0" className="text-white bg-transparent border-0 flex content-center justify-between">
            <div className="flex content-center capitalize cursor-pointer">
              <FaUserAlt className="text-white mr-3"/>
              {username}
            </div>
            <AiFillCaretDown className="text-white"/>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0" className="text-white bg-transparent border-0">
            <Card.Body onClick={onSignOut} className="text-white bg-transparent border-0">Sign Out</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </li>
  )
}

export default SideMenuAccordion;