import React from "react";

const PlaceDetailsCard = ({place}) => {

  return (
    <div className="p-5">
      <div className="bg-white rounded shadow-md mt-2 w-full py-2 px-3">
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">District</div>
          <div>{place.name}</div>
        </div>
        <div className="px-2 py-2 border-b-2 border-gray-200 flex">
          <div className="font-bold w-1/6">Region</div>
          <div>{place.region}</div>
        </div>
        <div className="px-2 py-2 flex">
          <div className="font-bold w-1/6">PLZ</div>
          <div>{place.plz}</div>
        </div>
      </div>
    </div>
  )
}

export default PlaceDetailsCard;