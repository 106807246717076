import { useState, useEffect } from "react";
import { Modal, Button, Dropdown, ModalTitle } from "react-bootstrap";
import { LAWYER, INSURER, NOTARY } from "../../constants";
import ModalInput from "../ModalInput/ModalInput";

const PartnerEditModal = ({show, onClose, partner, editPartner}) => {

  const [type, setType] = useState(LAWYER);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [zipCodes, setZipCodes] = useState("");
  const [apiMessage, setApiMessage] = useState("");

  useEffect(() => {
    setType(partner.type);
    setName(partner.name);
    setAddress(partner.address);
    setCity(partner.city);
    setPhone(partner.phone);
    setEmail(partner.email);
    setWebsite(partner.website);
    setZipCodes(partner.plz?.join(', '));
  }, [partner])

  const onEditPartner = async () => {
    setApiMessage('');

    const editedPartner = {
      "id": partner.id,
      "name": name,
      "address": address,
      "city": city,
      "phone": phone,
      "email": email,
      "website": website,
      "type": type,
      "plz": zipCodes.replaceAll(' ', '').split(','),
    }
    const mes = await editPartner(editedPartner);

    if (mes) {
      setApiMessage(mes);
      return;
    } 
    onClose();
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Partner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown className="flex items-center justify-center mb-4">
          <Dropdown.Toggle variant="secondary" className="w-full" id="dropdown-basic">
            {type}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-full text-center">
            <Dropdown.Item onClick={() => setType(LAWYER)}>{LAWYER}</Dropdown.Item>
            <Dropdown.Item onClick={() => setType(NOTARY)}>{NOTARY}</Dropdown.Item>
            <Dropdown.Item onClick={() => setType(INSURER)}>{INSURER}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <ModalInput fieldName="Name" setValue={setName} value={name}/>
        <ModalInput fieldName="Address" setValue={setAddress} value={address}/>
        <ModalInput fieldName="City" setValue={setCity} value={city}/>
        <ModalInput fieldName="Phone" type="number" setValue={setPhone} value={phone}/>
        <ModalInput fieldName="E-mail" setValue={setEmail} value={email}/>
        <ModalInput fieldName="Website" setValue={setWebsite} value={website}/>
        <ModalInput fieldName="PLZ" setValue={setZipCodes} value={zipCodes}/>
      </Modal.Body>
      <Modal.Footer>
        <p className="text-red-500">{apiMessage}</p>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button className="bg-blue-accent" onClick={onEditPartner}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PartnerEditModal;
