import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as workersActions from "../../redux/actions/workersActions";
import * as workerActions from "../../redux/actions/workerActions";
import { BsTable } from "react-icons/bs";
import TablePaginator from "../../components/TablePaginator/TablePaginator";
import WorkersDataTable from "../../components/WorkersDataTable/WorkersDataTable";
import WorkerAddModal from "../../components/WorkerAddModal/WorkerAddModal";
import WorkersFilterModal from "../../components/WorkersFilterModal/WorkersFilterModal";

const Workers = ({actions, workers}) => {

  const [page, setPage] = useState(1);
  const [errorOccured, setErrorOccured] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [plzFilter, setPlzFilter] = useState("");
  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseFilterModal = () => setShowFilterModal(false);
  const handleShowFilterModal = () => setShowFilterModal(true);

  useEffect(() => {
    if (showAddModal) return;

    setLoading(true);
    actions.fetchWorkers(nameFilter, cityFilter, plzFilter, page)
    .then(() => {
      if (workers !== undefined) {
        setLoading(false);
      }
    })
    .catch(error => {
      setErrorOccured(true);
    })
    .finally(() => {
      if (!errorOccured) {
        setLoading(false);
      }
    });
  },[nameFilter, cityFilter, plzFilter, page, actions, showAddModal])

  const setFilters = (nameFilter, cityFilter, plzFilter) => {
    setNameFilter(nameFilter);
    setCityFilter(cityFilter);
    setPlzFilter(plzFilter);
    setPage(1);
  }

  useEffect(() => {
    actions.fetchProfessions()
  }, [actions.fetchProfessions])

  const nextPage = () => {
    if (page != workers.pages) {
      setPage(page + 1)
    }
  }

  const prevPage = () => {
    setPage(Math.max(1, page - 1))
  }

  const createWorker = async (worker) => {
    try {
      await actions.createWorker(worker);
      actions.fetchWorkers(page);
    } catch (err) {
      return err.response.data;
    }
  }

  return (
    <>
    <div className="px-5 py-5">
    <div className="bg-blue-accent text-white absolute z-10 rounded py-3 px-4 top-6 text-xl ml-3 flex items-center">
      <BsTable className="text-white"/>
      <h3 className="text-white text-xl ml-3 my-0 font-normal">Workers</h3>
    </div>
    <WorkerAddModal showAddModal={showAddModal} handleCloseAddModal={handleCloseAddModal} createWorker={createWorker}/>
    <WorkersFilterModal showFilterModal={showFilterModal} handleCloseFilterModal={handleCloseFilterModal} setFilters={setFilters}/>
    {errorOccured && <h1 className="px-3 py-5">Error occured</h1>}
    {loading && <h1 className="px-3 py-5">Loading...</h1>}
    {!loading && 
      <div>
        <WorkersDataTable data={workers.workers} handleShowFilterModal={handleShowFilterModal} handleShowAddModal={handleShowAddModal} />
        <TablePaginator prevPage={prevPage} page={page} maxPage={workers.pages} nextPage={nextPage} />
      </div>}
    </div>
  </>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchWorkers: bindActionCreators(workersActions.fetchWorkers , dispatch),
      createWorker: bindActionCreators(workerActions.createWorker, dispatch),
      fetchProfessions: bindActionCreators(workersActions.fetchProfessions, dispatch)
    }
  }
}

function mapStateToProps(state) {
  return {
    workers: state.workers.workers,
    createdWorkerId: state.createdWorkerId,
    apiCallsInProgress: state.apiCallsInProgress,
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Workers);
